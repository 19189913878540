import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment, { isMoment } from "moment";
import "./App.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";

const localizer = momentLocalizer(moment);
const myEventsList = [];

function App() {
  return (
    <div className="App">
      <Row style={{ height: "100px", backgroundColor: "lightblue" }}>
        <Col xs={12} md={4}>
          Logo
        </Col>
        <Col md={6} />
        <Col xs={12} md={2}>
          User
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8} lg={5}>
          <div>
            <Calendar
              localizer={localizer}
              events={myEventsList}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 300 }}
              views={["month"]}
            />
            <Calendar
              localizer={localizer}
              events={[]}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 300 }}
              views={["month"]}
              date={moment()
                .add(1, "months")
                .toDate()}
            />
            <Calendar
              localizer={localizer}
              events={[]}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 300 }}
              views={["month"]}
              date={moment()
                .add(2, "months")
                .toDate()}
            />
            <Calendar
              localizer={localizer}
              events={[]}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 300 }}
              views={["month"]}
              date={moment()
                .add(3, "months")
                .toDate()}
            />
          </div>
        </Col>
        <Col xs={12} md={4} lg={5}>
          Content
        </Col>
        <Col xs={12} lg={2}>
          <Row>Ask the Expert</Row>
          <Row>Questions go here!</Row>
          <Row>
            <Row>Ask your own question</Row>
            <Row>
              <textarea>Type your quesiton here</textarea>
            </Row>
            <Row>
              <button type="submit">Submit</button>
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default App;
